.gsc-search-button.gsc-search-button-v2 {
  width: auto;
  height: auto;
  padding: 0px;
}

.gsc-search-button {
  margin-right: 5px;
  svg {
    width: 0px;
    height: 0px;
  }
}

table.gsc-search-box {
  margin: 0;
}

.gsc-input {
  height: 31px;
}

.gsc-search-box.gsc-search-box-tools {
  margin: 0;
}

.gsc-search-button.gsc-search-button-v2 {
  border: 0;
}

.gsc-search-button.gsc-search-button-v2:hover, .gsc-search-button.gsc-search-button-v2:focus {
  box-shadow: none;
  outline: none;
}

.search-icon {
  width: 56px;
  height: 56px;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgba(56, 152, 236, 0);
  background-image: url('../../assets/images/search-dark.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0.84;
  color: rgba(48, 54, 77, 0);
  cursor: pointer;
}

.close-search-icon {
  position: relative;
  margin-top: -65px;
  margin-left: 286px;
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgba(56, 152, 236, 0);
  background-image: url('../../assets/images/x.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0.84;
  color: rgba(48, 54, 77, 0);
  cursor: pointer;
}

#gsc-iw-id1.gsc-input-box {
  border: 0px;
}

input.gsc-input::-webkit-input-placeholder {
  font: 15px/24px Open Sans;
  letter-spacing: 0px;
  color: black;
}

#___gcse_0 {
  max-width: 223px;
  width: auto;

  .gsst_a, .gscb_a {
    color: black;
  }

  .gsc-search-button-v2 {
    @extend .search-icon;
    svg {
      fill: black;
    }
  }
}

#___gcse_1 {
  width: 100%;
}

.mobile-close-search {
  z-index: 1002;
  display: none;
}

.search-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
}

.mobile-search-toggle {
  display: none;
  margin-left: 282px;
  margin-top: -22px;
}

.gsc-search-button-v2 svg {
  width: 0 !important;
  height: 0 !important;
}

@media screen and (max-width: 991px) {
  .search-block {
    display: none;
    position: absolute;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    padding-right: 0px;
    padding-left: 0px;
  }

  .mobile-search-toggle {
    display: inline-flex;
  }

  .gsc-input {
    width: 100%;
    height: 100%;
  }

  #gs_st50 {
    display: none;
  }

  #___gcse_0 {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 10px;

    .gsc-control-searchbox-only {
      height: 100%;
      display: flex;
      align-content: center;
      align-items: center;
    }
  }

  .gsc-search-box {
    margin: 0;
  }
}

#gsc-i-id1::placeholder {
  font-size: 16px;;
}

@media screen and (min-width: 992px) {
  #gs_id50 {
    margin-bottom: 10px;
    margin-left: 6px;
  }

  #gs_cb50 {
    font-weight: 100 !important;
    font: 25px / 37px Open Sans;
    color: #91949C !important;
    margin-right: 1px;
  }

  .gsc-search-button {
    margin-top: -8px !important;
  }

  #___gcse_0 {
    max-width: 223px;
    width: 223px;
    height: 41px;
    margin-top: -29px;
    margin-bottom: 9px;
    margin-left: 255px;
    border: 1px solid #EFEFEF;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
