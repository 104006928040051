.page-header {
    --header-height: 88px;
  
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    display: flex;
    color: #212a3b;
    font: 16px/24px Open Sans;
    z-index: 140;
    height: var(--header-height);
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.05);
    font-family: 'Open Sans', sans-serif;
  
    @media screen and (max-width: 991px) {
      --header-height: 56px;
  
      margin-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
  
      &.menu-opened {
        z-index: 101;
      }
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 10px;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
  }

  .menu-index {
    z-index: 100;
  }
  
  .profile-button {
    color: #30364d !important;
    min-width: 100px;
    border-color: #30364d;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 4px;
    transition: border-color .3s ease, transform .3s ease, box-shadow .3s ease;
  
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 8px 22px;
    border: 1px solid #212a3b;
    background-color: transparent;
    background-image: none;
  
    &:hover {
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    }
  }
  

  .page-header__wrapper {
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
  }
  
  .header-nav {
    display: flex;
    justify-content: flex-end;
    flex: 1 0 auto;
    padding: 0;
    margin: 0;
    padding-left: 15px;
    margin-top: -48px;
    list-style: none;
  
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  
  .header-nav__item {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 16px;
  
    > a,
    .header-nav__drop a {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
    &:hover {
      text-decoration: underline !important;
    }
  }
  
  .header-nav__link {
    color: #212A3B !important;
    font-size: 15px;
    line-height: 24px;
    padding: 8px 10px;
    border-radius: 4px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
  
    .header-nav__drop[open] & {
      background-color: #EDEFF250;
    }
  }
  
  .header-nav__drop-button {
    font-size: 0;
    display: flex;
    align-items: center;
  }
  
  .header-nav__drop-opener {
    display: flex;
    align-items: center;
  }
  
  .header-nav__link-icon {
    --thickness: 1.2;
  
    margin: 0 10px;
  
    .header-nav__drop[open] & {
      transform: rotateX(180deg);
    }
  }
  
  .header-nav__drop-content {
    margin-top: 26px;
    position: absolute;
    top: 100%;
    left: -8px;
    visibility: hidden;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 3px 6px #0000000D;
    border: 1px solid #C9D0D9;
  
    .header-nav__drop[open] & {
      visibility: visible;
    }
  }
  
  .header-subnav {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
  }
  
  .header-subnav__item {
    width: 240px;
    padding: 0;
  
    &:first-child {
      padding-top: 8px;
    }
  
    &:last-child {
      padding-bottom: 8px;
    }
  }
  
  .header-subnav__drop {
    width: 100%;
  }
  
  .header-subnav__drop-button {
    display: flex;
    font-size: 0;
  }
  
  .header-subnav__drop-opener {
    display: flex;
  }
  
  .header-subnav__item-icon {
    --thickness: 1.2;
  
    margin: 10px 10px 10px 5px;
    transition: transform 0.3s linear;
  }
  
  .header-subnav__link {
    padding: 7px 8px;
    font-size: 13px;
    width: 100%;
    padding-left: 30px;
    line-height: 18px;
    color: #212A3B;
    display: block;
  
    &:hover,
    &:focus {
      background-color: #EDEFF250;
      text-decoration: underline !important;
    }
  
    .header-subnav__item-icon + & {
      width: auto;
      padding-left: 8px;
    }
  }
  
  .header-subnav__drop-content {
    display: none;
    top: -1px;
    right: 100%;
    width: max-content;
    max-width: calc(1220px - 324px);
    height: calc(100% + 2px);
    position: absolute;
  
    .header-subnav__drop[open] & {
      display: flex;
    }
  
    @media screen and (max-width: 1280px) {
      max-width: calc(100vw - 410px);
    }
  }
  
  .header-subnav__drop-container {
    display: flex;
    padding: 8px 15px 8px 10px;
    background-color: #fff;
    border-radius: 4px 0 0 4px;
    border: 1px solid #C9D0D9;
    border-right: 0;
    box-shadow: -3px 3px 6px #0000000d;
    overflow: auto;
  
    &::after {
      content: '';
      width: 5px;
      position: absolute;
      top: 0;
      right: -4px;
      bottom: 0;
      background-color: #fff;
      border: 1px solid #C9D0D9;
      border-right: 0;
    }
  }

  .header-subnav__drop-column {
    &:not(:first-child) {
      margin-left: 6px;
    }
  }

  .header-subnav__drop-title {
    font-size: 15px;
    line-height: 22px;
    padding: 7px 8px;
    font-weight: bold;
    max-width: 210px;
  }

  .header-subnav__drop-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .header-subnav__drop-link {
    padding: 7px 8px;
    font-size: 13px;
    min-width: 130px;
    max-width: 210px;
    line-height: 18px;
    color: #212A3B;
    display: block;
  
    &:hover {
      background-color: #EDEFF250;
    }
  }
  
  .profile-menu {
    margin-top: 4px;
    min-width: 242px;
    padding: 20px 10px;
    box-shadow: 0px 3px 6px #0000000d;
  }
  
  .profile-menu__item {
    margin: 1px 0;
    display: block;
    padding: 8px 20px;
    font-size: 16px;
    line-height: 24px;
    color: #212A3B;
    border-radius: 3px;
    white-space: nowrap;
  
    &:hover {
      background-color: #EDEFF2;
    }
  }
  
  .mobile-nav {
    margin-left: 328px;
    padding: 0;
    position: static;
  
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
  
  .logo {
    @media screen and (max-width: 991px) {
      margin-bottom: -31px;
      padding-top: 0px !important;
    }
  }

  .mobile-nav__toggler {
    margin-top: -40px;
    position: relative; 
    float: right;
    --indicator-pos: 8px;
    --indicator-height: 2px;
    --indicator-width: 22px;
  
    padding-bottom: 48px;
    border: 0;
    opacity: 0.84;
    display: flex;
    color: #212a3b;
    width: var(--header-height);
    height: var(--header-height);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: 0;
    z-index: 1;
  
    &:not(.collapsed) {
      --indicator-pos: 0;
      z-index: 1;
    }
  
    &:before,
    &:after {
      content: '';
      height: var(--indicator-height);
      width: var(--indicator-width);
      display: block;
      position: absolute;
      background-color: currentColor;
      transition: transform 0.3s linear;
    }
  
    &:before {
      transform: translateY(var(--indicator-pos));
    }
  
    &:after {
      transform: translateY(calc(-1 * var(--indicator-pos)));
    }
  
    &:not(.collapsed) {
      &:before {
        transform: rotate(45deg);
      }
    
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
  
  .header-socials__icon {
    --size: 48px !important;
    width: var(--size);
    height: var(--size);
    display: flex;
    color: #929baa;
    border-radius: 3px;
    align-items: center;
    background-color: #fff;
    justify-content: center;
    border: 1px solid #e4e7ec;
    cursor: pointer;
  
    &:hover {
      border-color: #525f70;
      color: #000;
    }
  }

  .social {
    z-index: 1;
  }

  .socials {
    grid-gap: 10px;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .mobile-nav__toggler-indicator {
    opacity: 0;
    height: var(--indicator-height);
    width: var(--indicator-width);
    background-color: currentColor;
    transition: opacity 0.3s linear;
  
    .collapsed & {
      opacity: 1;
    }
  }
  
  .mobile-nav__dropdown {
    position: absolute;
    right: 0;
    left: 0;
    top: var(--header-height);
    background: white;
    border-top: 1px solid #E4E7EC;
  }
  
  .mobile-nav__content {
    overflow-y: auto;
    padding-top: 18px;
    height: calc(100vh - var(--header-height));
  }
  
  .mobile-nav__content-holder {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  .mobile-nav__item {
    color: #212a3b !important;
    padding: 12px 20px;
    min-height: 48px;
    color: inherit;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    -webkit-appearance: none;
    position: relative;
    z-index: 1;
    pointer-events: auto;
    display: flex;
    align-items: center;
  
    &--secondary {
      font-size: 15px;
    }
  }
  
  .mobile-nav__drop-1,
  .mobile-nav__drop-2,
  .mobile-nav__drop-3,
  .mobile-nav__drop-4 {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }
  
  .mobile-nav__drop-1 {
    --drop-left-indent: 20px;
    --drop-opener-bg: #EDEFF2;
  }
  
  .mobile-nav__drop-2 {
    --drop-left-indent: 30px;
    --drop-opener-bg: #F2F5FA;
  }
  
  .mobile-nav__drop-3 {
    --drop-left-indent: 40px;
    --drop-opener-bg: #FAFCFF;
  }
  
  .mobile-nav__drop-4 {
    --drop-left-indent: 50px;
  }
  
  .mobile-nav__drop-1-content,
  .mobile-nav__drop-2-content,
  .mobile-nav__drop-3-content,
  .mobile-nav__drop-4-content {
    z-index: 1;
    background-color: var(--drop-bg, #fff);
  }
  
  .mobile-nav__drop-1-link,
  .mobile-nav__drop-2-link,
  .mobile-nav__drop-3-link,
  .mobile-nav__drop-3-text,
  .mobile-nav__drop-4-link {
    min-height: 48px;
    display: flex;
    font-size: 15px;
    line-height: 24px;
    align-items: center;
    align-self: start;
  
    &::before {
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
  
      details[open] + & {
        background-color: var(--drop-opener-bg, transparent);
      }
    }
  }
  
  .mobile-nav__drop-1-link {
    font-size: 18px;
    line-height: 24px;
  }
  
  .mobile-nav__drop-1-opener,
  .mobile-nav__drop-2-opener,
  .mobile-nav__drop-3-opener {
    right: 0;
    left: 0;
    top: 0;
    height: 48px;
    font-size: 0;
    display: flex;
    outline: none;
    color: #929BAA;
    position: absolute;
    padding: 0 15px;
    box-sizing: content-box;
  
    &::marker {
      display: none;
    }
  }
  
  .mobile-nav__drop-n-opener-icon {
    display: flex;
    width: 40px;
    height: 100%;
    margin-left: auto;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    transition: transform 0.3s linear;
  }
  
  .mobile-nav__drop-n-link,
  .mobile-nav__drop-n-text {
    width: 100%;
    color: inherit;
    position: relative;
    padding: 12px var(--drop-left-indent, 20px);
  }
  
  .mobile-nav__drop-n-link {
    z-index: 1;
    color: #212a3b !important;
  }
  
  .mobile-nav__drop-n-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .mobile-nav__drop-n-item {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
  }
  
  .mobile-nav__socials {
    padding: 25px 20px;
  }

  .mobile-nav__footer {
    padding-right: 24px;
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
.mobilee-nav__item {
  padding: 12px 20px;
  min-height: 48px;
  display: flex;
  color: inherit;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  align-items: center;
  -webkit-appearance: none;
}

.profile-button-menu {
  padding: 20px 12px;
  margin-top: 6px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  width: 244px;
  display: none;
  z-index: 1;

  &.active {
    display: block;
  }
}

.profile-button-container {
  position: relative;
}

.profile-button-container.active .profile-button-menu {
  display: block;
}

.profile-menu-a {
  margin: 1px 0;
  display: block;
  padding: 8px 20px;
  font-size: 16px;
  line-height: 24px;
  color: #000 !important;
  border-radius: 3px;
  white-space: nowrap;
}

.profile-button-container .profile-menu-a:hover {
  background-color: rgb(237, 239, 242);
}
