.page-footer {
    padding-top: 33px !important;
    padding: 56px 0;
    background-color: #EAEEF2;
    font-family: Open Sans,sans-serif !important;
  
    @media screen and (max-width: 991px) {
      padding: 30px 0;
    }
  }


.profile-button-container {
  position: relative;
}

.profile-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
}

.profile-button-container:hover .profile-menu {
  display: block;
}

.profile-button-menu {
  list-style: none;
}

.footer-brand {
  margin-top: -6px;
  margin-bottom: 40px;
}

.footer-nav {
  display: flex;
  flex-direction: column;.footer-brand {
  margin-top: -6px;
  margin-bottom: 40px;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  grid-column: var(--column, 3);

  &:nth-child(1),
  &:nth-child(2) {
    grid-row: 1/-1;
  }
}

.footer-nav__title {
  letter-spacing: 0;
  color: #30364D;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 23px;
  margin-bottom: 16px;
}

.footer-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.footer-nav__item {
  &:not(:first-child) {
    margin-top: 12px;
  }
}

.footer-nav__link {
  color: #525F70;
}

.footer-nav__link:hover {
  color: black;
}

.footer-top-mobile {
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.footer-mobile-nav {
  display: flex;
  flex-direction: column;

  .mobile-nav__drop-1 {
    order: var(--order);
  }
}

.footer-socials {
  display: flex;
  grid-column: 1;

  @media screen and (max-width: 991px) {
    margin: 50px 20px 20px;
  }
}

.wrapper-content {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 10px;
}

.footer-bottom {
  display: flex;
  font-size: 13px;
  line-height: 18px;
  padding-top: 33px;
  justify-content: space-between;
}

.footer-bottom__copyright {
  color: #212A3B;
}

.footer-bottom__links {
  display: flex;
  font-size: 13px;
}

.footer-bottom__link {
  padding: 0 5px;
  color: #212A3B;
  margin-left: 30px;

  &:hover {
    text-decoration: underline !important;
  }
}

.footer-address {
  padding-top: 20px;
  color: #525f70;
  font-size: 13px;
  line-height: 20px;
}

.footer-top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
  grid-column: var(--column, 3);

  &:nth-child(1),
  &:nth-child(2) {
    grid-row: 1/-1;
  }
}

.footer-nav__title {
  letter-spacing: 0;
  color: #212A3B;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 23px;
  margin-bottom: 16px;
}

.footer-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.footer-nav__item {
  &:not(:first-child) {
    margin-top: 12px;
  }
}

.footer-nav__link {
  color: #525F70;
}

.footer-nav__link:hover {
  color: black;
}

.footer-top-mobile {
  @media screen and (min-width: 992px) {
   display: none;
  }
}

.footer-mobile-nav {
  display: flex;
  flex-direction: column;

  .mobile-nav__drop-1 {
   order: var(--order);
  }
}

.footer-socials {
  display: flex;
  grid-column: 1;
}

.wrapper-content {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 10px;
}

.footer-socials__link {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  display: flex;
  color: #929BAA;
  border-radius: 3px;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  border: 1px solid #E4E7EC;
  cursor: pointer;

  @media screen and (max-width: 991px) {
      --size: 48px;
  }

  svg {
    width: var(--size);
    height: var(--size);
  }

  &:hover {
    border-color: #a9aec3;
  }
}

.footer-bottom {
  display: flex;
  font-size: 13px;
  line-height: 18px;
  padding-top: 33px;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
    padding: 0 20px;
  }
}

.footer-bottom__copyright {
  color: #212A3B;
}

.footer-bottom__links {
  display: flex;
  font-size: 13px;
  color: #30364D;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 56px;
    align-items: flex-start;
  }
}

.footer-bottom__link {
  color: #30364D !important;
  padding: 0 5px;
  font-size: 13px;
  margin-left: 30px;

  &:hover {
      text-decoration: underline !important;
  }

  @media screen and (max-width: 991px) {
    margin-left: 0;
    padding: 14px 0;
  }
}

.footer-address {
  padding-top: 20px;
  color: #525f70;
  font-size: 13px;
  line-height: 20px;
}

@media screen and (max-width: 479px) {
  .footer-address.footer-address-mobile {
    margin-left: 0;
    padding-left: 9px;
    text-align: left;
  }
}

@media screen and (max-width: 991px) {
  .footer-address.footer-address-mobile {
    padding-top: 20px;
    padding-left: 20px;
  }
}

.footer-top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
}

.footer-socials__icon {
  --size: 32px !important;
  width: var(--size);
  height: var(--size);
  display: flex;
  color: #929baa;
  border-radius: 3px;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  border: 1px solid #e4e7ec;
  cursor: pointer;

  &:hover {
    border-color: #525f70;
    color: #000;
  }
}
