@import "../../../assets/scss/theme";

  .filters_container {
    width: 100%;
    padding-bottom: 40px;
  }

  .filters_content {
    height: auto;
    background-color: white;
  }

  .filters_header {
    height: 25%;
    position: relative;
    padding-left: 20px;
    padding-top: 20px;
    width: 100px;
  }

  .filters_title {
    color: #212A3B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
  }

  .less_more_filters {
    justify-content: flex-end;
    display: flex;
    margin-right: 25px;
    margin-top: -35px;
    padding-bottom: 10px;

    @media (min-width: 1030px) {
      display: none;
    }
  }

  .filters_table_wrapper {
    padding-top: 10px;
    height: 38%;

    .uncollapsed {
      margin-top: -1px;
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-right: 0.3em solid transparent;
      border-left: 0.3em solid transparent;
      border-top: 0.3em solid;
      border-bottom: 0;
    }

    .collapsed {
      margin-top: -1px;
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-right: 0.3em solid transparent;
      border-left: 0.3em solid transparent;
      border-top: 0;
      border-bottom: 0.3em solid;
    }
  }

  .filters_table {
    width: 100%;
    height: 100%;
    border: none;
    border-collapse: separate;
    border-spacing: 20px 0px;

    @media (max-width: 1030px) {
      tr {
        display: grid;
      }
      td {
        width: auto;
        padding-bottom: 8px;
        display: inline;
      }
    }
  }

  .filters_td{
      width: 20%;   
  }
  
  .filters_select{
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: 2px solid lightgray;
      padding: 0px 10px;
      cursor: pointer;
  }
  
  .filters_select_option:hover{
      background-color: red;
  }
  
  .filters_select_option:checked{
      background-color: lightgray;
  }
  
  .filters_table{
      width: 100%;
      height: 100%;
      border: none;
      border-collapse: separate;
      border-spacing: 20px 0px;
  }
  
  .filters_reset_btn{
      color: #343A40;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;   width: 118.249px;
      height: 36px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border-radius: 4px;
      border: 1px solid black;
      height: 100%;
      padding: 8px 12px;
      background-color: white;
      cursor: pointer;

      @media (max-width: 1030px) {
        float: left;
      }
  }
  
  .filters_update_btn{
      width: 125.706px;
      height: 36px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border-radius: 4px;
      border: 1px solid #DB4746;
      height: 100%;
      padding: 8px 12px;
      background-color: #DB4746;
      color: white;
      margin-left: 20px;
      cursor: pointer;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    .filters-dropdown {
      font-size: 16px;
    }
  }

  .default_settings_btn {
    @media (min-width: 480px) {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      float: left;
    }

    @media (min-width: 1030px) {
      margin-top: -40px;
      margin-bottom: 20px;
    }
  }

  .default_settings_btn_text {
    padding: 8px 0px;
    color: #DB4746;
    cursor: pointer;
    text-decoration: underline;

    @media (max-width: 1030px) {
      float: left;
      padding: 20px 0px 0px;
    }
  }

