@import "../../../assets/scss/theme";

  .navigation-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .navigation-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navigation-list li {
    display: flex;
    margin-bottom: 20px;

    @media (min-width: 640px) {
      justify-content: flex-end;
    }
  }
  
  .navigation-bar li {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    color: #212A3B;
  }
  
  .navigation-bar li.active {
    color: #DB4746;
    text-decoration: underline;
  }

  .navigation-bar {
    width: auto;
    float: left;
    padding-right: 40px !important;
    margin-left: 20px;

    @media (min-width: 640px) {
      margin-top: 322px;
      position: sticky;
      width: auto;
      float: left;
      padding-right: 40px !important;
      margin-left: 20px;
      top: 150px;
    }
  }
  
  .navigation-content {
    @media (min-width: 640px) {
      margin-right: 40px;
    }
    .title {
      font-size: 26px;
      font-weight: 500
    }
  }
