.chart-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: #212A3B;
  font-family: Poppins;
}

.chart-text {
  color: #929BAA;
  margin-top: 10px;
  margin-bottom: 20px;
}