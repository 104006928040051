@import "../../assets/scss/theme";

  .content-container {
    @media (min-width: 640px) {
      margin-left: 248px;
    }
  }

  .analytic-title {
    margin-top: 40px;
    margin-bottom: 22px;
    font-family: 'Poppins';
    color: rgb(0, 0, 0);
    font-size: 32px !important;
    font-style: normal;
    line-height: 40px;
    text-transform: capitalize;
    @media (max-width: 475px) {
      display: none;
    }
  }

  .analytic-title-mobile {
    margin-bottom: 13px;
    @media (min-width: 476px) {
      display: none;
    }
  }

  .analytic-title-mobile-text {
    padding-left: 18px;
    font-weight: 500;
    display: block;
    font-family: 'Poppins';
    color: black;
    font-size: 32px !important;
    font-style: normal;
    line-height: 40px;
    text-transform: capitalize;
  }

  @media print {
    .page-content {
      background-color: white;
    }

    .content-container {
      margin-left: 0px;
    }
  }