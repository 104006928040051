.login-block {
  font-family: 'Open Sans', sans-serif;
  color: #212a3b !important;

  @media (min-width: 820px) {
    width: 600px;
    padding: 40px 70px !important;
    margin-top: 30px;
  }

  .title {
    font: 500 28px/30px Poppins;
    margin-bottom: 30px;
  }

  label {
    color: #525f70;
    margin-bottom: 2px;
  }

  .forget-password-btn {
    text-decoration: underline !important;
    float: right;
  }

  .sign-in-btn-block {
    width: 95px;
    padding: 15px 0;

    .sign-in-btn {
      border-color: #30364d;
      font-family: Open Sans,sans-serif;
      color: #30364d;
      background: white;
    }
  }

  .join-link {
    display: flex;

    a {
      color: unset;
      text-decoration: underline !important;
    }
  }

  .toggle-password {
    display: flex;
    float: right;
    margin-top: -24px;
    margin-right: 12px;
    cursor: pointer;
    color: black;
    text-decoration: underline;
  }
}