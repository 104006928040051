.collapsible .body {
  background-color: #EEF1FD;
  padding: 0px 35px 5px 35px;

  .title {
    font-size: 13px;
    color: #929BAA;
    font-weight: 200
  }
}
.collapsible .header {
  background-color: #EEF1FD;
  cursor: pointer;
  padding: 17px 35px;

  p {
    margin-bottom: 0rem !important;
  }
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  float: right;
  margin-top: 4px;
  border-color: #212A3B;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 7px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.recommendation-section {
  justify-content: space-between;
  column-count: 2;
  column-gap: 56px;
  padding-bottom: 20px;
  @media (max-width: 1030px) {
    column-count: 1;
  }
}

.recommendation-item {
  .question-title {
    font-size: 14px;
    font-weight: 500;
    color: rgb(0, 0, 0);
  }
  break-inside: avoid;
}