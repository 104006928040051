.notification-main-text {
  margin-bottom: 4px !important;
  color: #929BAA !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  width: 266px;
  height: 80px;
}

.notification-ok-button {
  width: 62px;
  height: 36px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px !important;
  width: 70 !important;
  background-color: #DB4746 !important; 
  border-color: #DB4746 !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.notification-text {
  padding-left: 42px !important;
}

.notification-header-text {
  width: 266px;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.main-settings-header {
  color: #000000;
}

.main-settings-text {
  color: #929BAA;
}

.modal-window {
  @media screen and (min-width: 462px) {
    width: 446px !important;
    height: 350px !important;
  }
}
